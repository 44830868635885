import React from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import { ThemeProvider } from '@emotion/react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { indexChakraTheme, theme } from './styles/themeConfig'
import { store, persistor } from './store'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ChakraProvider resetCSS theme={indexChakraTheme}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ChakraProvider>
    </PersistGate>
  </Provider>
)
