import { paths as onBoarding } from './onBoarding/paths'
import { paths as landingPage } from './landingPage/paths'
import { paths as wall } from './wall/paths'
import { paths as account } from './account/paths'
import { paths as download } from './download/paths'

export const paths = {
  ...onBoarding,
  ...landingPage,
  ...wall,
  ...account,
  ...download,
}
