import * as React from 'react'

const SvgFaMore = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M11.75 1.1a.75.75 0 0 0-1.5 0v9.15H1.1a.75.75 0 0 0 0 1.5h9.15v9.15a.75.75 0 0 0 1.5 0v-9.15h9.15a.75.75 0 0 0 0-1.5h-9.15V1.1Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaMore
