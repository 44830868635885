import { call, put, cancelled } from 'redux-saga/effects'
import createDefaultHeaders from './createDefaultHeaders'

export default ({ apiCall, actions, payloadKey, isAuthenticated = true }) =>
  function* defaultSaga(action) {
    try {
      const { payload } = action
      yield put(actions.loading())
      const result = yield call(apiCall(), {
        data: payload,
        headers: yield createDefaultHeaders(isAuthenticated),
      })

      if (payloadKey) {
        yield put(actions.success({ [payloadKey]: result }))
      } else {
        yield put(actions.success())
      }
    } catch (error) {
      yield put(actions.error({ error }))
    } finally {
      if (yield cancelled()) {
        /* NOTHING TO DO */
      }
    }
  }
