import { selectors as session } from './session'
import { selectors as contact } from './contact'
import { selectors as UXSite } from './UXSite'
import { selectors as user } from './user'
import { selectors as payment } from './payment'
import { selectors as invitation } from './invitation'
import { selectors as toast } from './toast'
import { selectors as weather } from './weather'

export const selectors = {
  session,
  contact,
  UXSite,
  user,
  payment,
  invitation,
  toast,
  weather,
}
