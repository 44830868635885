import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import backgroundApp from '../../../images/onBoarding/backgroundApp.png'

export const StyledContainer = styled(Box)`
  ${(props) =>
    props.withBackgroundImage &&
    `
   background-image: linear-gradient(
              180deg,
              rgba(46, 38, 35, 0.7) 0%,
              rgba(46, 38, 35, 0.4) 50%,
              #2e2623 100%
            ),
            url(${backgroundApp});
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
   `}

  min-height: 100vh;
  position: relative;
`
