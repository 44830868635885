import * as React from 'react'

const SvgFaUsers = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 35 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.864 6.24c0-2.15 1.702-3.855 3.753-3.855 2.05 0 3.752 1.705 3.752 3.855 0 2.151-1.702 3.855-3.752 3.855-2.051 0-3.753-1.704-3.753-3.855ZM17.617.198c-3.302 0-5.94 2.727-5.94 6.042 0 3.316 2.638 6.043 5.94 6.043 3.302 0 5.94-2.727 5.94-6.043 0-3.315-2.638-6.042-5.94-6.042Zm0 14.284c4.097 0 7.424 2.903 7.978 6.61H9.638c.554-3.707 3.882-6.61 7.979-6.61ZM7.463 20.84c-.061.44-.093.889-.093 1.346 0 .604.49 1.094 1.093 1.094H26.77c.604 0 1.094-.49 1.094-1.094a9.67 9.67 0 0 0-.094-1.346h5.703c.604 0 1.093-.49 1.093-1.094 0-3.547-2.239-6.54-5.397-7.87A5.322 5.322 0 0 0 25.52 2.68a1.094 1.094 0 1 0 0 2.187 3.134 3.134 0 1 1 0 6.268h-.034a1.094 1.094 0 0 0 0 2.188c3.457 0 6.238 2.344 6.792 5.33h-4.57c-.17 0-.33.038-.474.107-1.449-3.791-5.232-6.465-9.617-6.465-4.344 0-8.097 2.623-9.576 6.357H2.652c.554-2.985 3.335-5.33 6.792-5.33a1.094 1.094 0 0 0 0-2.187H9.41a3.134 3.134 0 1 1 0-6.268 1.094 1.094 0 1 0 0-2.188 5.322 5.322 0 0 0-3.649 9.196C2.603 13.206.364 16.198.364 19.745c0 .605.49 1.095 1.094 1.095h6.005Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaUsers
