import React from 'react'
import { Box, Image, Stack } from '@chakra-ui/react'
import styled from 'styled-components'
import { FormCard } from '../../../components/cards'
import { FaChecked, FaPhone } from '../../../components/Svg'
import placeholderContact from './placeholderContact.png'

const WrapperContact = styled(Box)`
  border-radius: 10px;
  background: rgba(254, 133, 104, 0.16);
  position: relative;
  margin-top: 100px !important;
  padding-top: 30px;
  .avatar {
    position: absolute;
    left: 50%;
    top: -50px;
    margin-left: -50px;
    box-shadow: 2px 4px 0 rgba(0, 0, 0, 0.08);
  }
  .avatar__icon {
    position: absolute;
    top: 28px;
    left: 50%;
    margin-left: 30px;
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    color: white;
    font-size: 14px;
  }
`

const Index = () => (
  <FormCard customStyle={{ padding: 0 }}>
    <Stack spacing={5} mb={12}>
      <Box as="h3" textStyle="h3" color="secondary.400">
        Vous avez une question ?
      </Box>
      <Box as="h1" textStyle="h1" color="primary.400">
        N’hésitez pas à contacter Index
      </Box>
      <WrapperContact>
        <Image
          className="avatar"
          borderRadius="full"
          boxSize={100}
          bg="white"
          borderColor="primary.400"
          src={placeholderContact}
          alt="image de télé-conseillère"
        />
        <Box className="avatar__icon" background="lightblue.500">
          <FaChecked />
        </Box>
        <Stack spacing={7} p={12}>
          <Box as="h3" textStyle="h3">
            <Box as="span" fontWeight="900" color="primary.400">
              Véronique
            </Box>{' '}
            est disponible pour vous renseigner
          </Box>
          <Box as="p" textStyle="p">
            Du lundi au vendredi <br />
            08:30 - 12:00 • 14:30 - 19:00
          </Box>
          <Box
            bg="white"
            color="lightblue.500"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={10}
            p={6}
          >
            <FaPhone mr={3} fontSize="20px" />
            <Box as="h1" textStyle="h1" fontSize="20px" ml={4}>
              0800 272 252 272
            </Box>
          </Box>
        </Stack>
      </WrapperContact>
    </Stack>
  </FormCard>
)

export default Index
