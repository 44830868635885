import * as React from 'react'

const SvgFaPhoto = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    style={{
      enableBackground: 'new 0 0 50 50',
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <circle cx={25} cy={25} r={25} fillOpacity={0.16} fill="#6891FB" />
    <path
      d="M25 30.5c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0 1c2.8 0 5-2.2 5-5s-2.2-5-5-5-5 2.2-5 5 2.2 5 5 5z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: 'currentcolor',
      }}
    />
    <path
      d="M21 16.5c.3-.6 1-1 1.7-1H27c.7 0 1.4.4 1.7 1l.8 1.7c.1.3.4.5.8.5h2.4c.9 0 1.8.4 2.4 1 .6.6 1 1.5 1 2.4v9.5c0 .9-.4 1.8-1 2.4-.6.6-1.5 1-2.4 1H16.9c-.9 0-1.8-.4-2.4-1-.6-.6-1-1.5-1-2.4v-9.5c0-.9.4-1.8 1-2.4.6-.6 1.5-1 2.4-1h2.4c.3 0 .6-.2.8-.5l.9-1.7z"
      style={{
        fill: 'none',
        stroke: 'currentcolor',
        strokeWidth: 2,
      }}
    />
  </svg>
)
export default SvgFaPhoto
