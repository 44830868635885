import React, { Suspense, useState } from 'react'
import _ from 'lodash'
import { Box, Image } from '@chakra-ui/react'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { StyledNavbar, NavbarWrapper } from '../../../styles/layout'
import { LogoIndex } from '../../Logo'
import NavLinks from './NavLinks'
import { FaCalendar } from '../../Svg'
import { paths } from '../../../router/paths'

const Contacts = React.lazy(() => import('../../../views/Contacts'))

export const StyledImage = styled(Image)`
  mask-image: radial-gradient(circle at left, transparent 25px, black 25px);
  &:nth-child(1) {
    mask-image: none;
  }
`

export const Navbar = ({ user, contacts }) => {
  const [renderContacts, setRenderContacts] = useState(false)

  return (
    <NavbarWrapper className={renderContacts && 'is__active'}>
      <StyledNavbar>
        <Link to={paths.WALL}>
          <LogoIndex color="white" height={41} />
        </Link>
        <NavLinks renderContacts={renderContacts} />
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            ml={4}
            h={14}
            backgroundColor="rgba(255, 255, 255, 0.24)"
            boxShadow="2px 4px 0px rgba(0, 0, 0, 0.08)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
            padding="8px 24px 8px 6px"
            onClick={() => setRenderContacts(!renderContacts)}
            bg={renderContacts && 'white'}
            cursor="pointer"
          >
            <Box display="flex" mr="24px">
              {contacts.contacts &&
                _.filter(contacts.contacts, (e) => e.user.profilePicture).map(
                  (contact, i) => {
                    if (i < 3) {
                      return (
                        <StyledImage
                          key={`${`${i}user`}`}
                          borderRadius="full"
                          boxSize="50px"
                          bg="white"
                          src={contact.user.profilePicture.thumbnailSizeUrl}
                          alt={`image de ${user.firstname}`}
                          h={45}
                          w={45}
                          ml={i !== 0 && '-20px'}
                        />
                      )
                    }
                    return null
                  }
                )}
            </Box>
            <Box
              as="p"
              textStyle="p"
              fontSize="15px"
              fontWeight="700"
              color={renderContacts ? 'lightblue.400' : 'white'}
              flex={1}
              userSelect="none"
            >
              Mes proches
            </Box>
          </Box>
          <Box
            ml={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            h={14}
            w={14}
            fontSize={19.5}
            borderRadius={100}
            backgroundColor="rgba(255, 255, 255, 0.24)"
            boxShadow="2px 4px 0px rgba(0, 0, 0, 0.08)"
          >
            <FaCalendar color="white" />
          </Box>
          <NavLink to={paths.USER_INFORMATIONS}>
            {({ isActive }) => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {user?.profilePicture?.thumbnailSizeUrl ? (
                  <Image
                    ml={4}
                    borderRadius="full"
                    boxShadow="2px 4px 0px 0px rgba(0, 0, 0, 0.08)"
                    boxSize={14}
                    bg="white"
                    border={isActive && !renderContacts && `3px solid`}
                    borderColor="primary.400"
                    src={user.profilePicture.thumbnailSizeUrl}
                    alt={`image de ${user.firstname}`}
                  />
                ) : (
                  <Box
                    background="secondary.400"
                    borderRadius="full"
                    ml={4}
                    w={14}
                    h={14}
                    boxShadow="2px 4px 0px 0px rgba(0, 0, 0, 0.08)"
                  />
                )}
              </>
            )}
          </NavLink>
        </Box>
      </StyledNavbar>
      {/* TODO: replace by loader */}
      <Suspense fallback={<div>Chargement...</div>}>
        {renderContacts && <Contacts />}
      </Suspense>
    </NavbarWrapper>
  )
}
