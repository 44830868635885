import { contact } from './contact'
import { auth } from './auth'
import { payment } from './payment'
import { user } from './user'
import { invitation } from './invitation'
import { weather } from './weather'

const api = { auth, payment, user, contact, invitation, weather }

export default api
