import { createBrowserRouter } from 'react-router-dom'
import landingPage from './landingPage'
import onBoarding from './onBoarding'
import wall from './wall'
import account from './account'
import download from './download'

export const router = createBrowserRouter([
  ...landingPage,
  ...onBoarding,
  ...wall,
  ...account,
  ...download,
])
